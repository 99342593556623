<template>
  <div class="subPage">
    <div class="subPageTitle">
      <div class="subPageTitleText">
        <p>我的存单</p>
      </div>
    </div>
    <div class="searchList">
      <el-row :gutter="10">
        <el-col :span="4">
           <el-input v-model.number="searchForm.holdId" placeholder="请输入申请持仓ID"></el-input>
        </el-col>
        <el-col :span="4">
            <el-select class="width-100" v-model="searchForm.storageId"  placeholder="请选择仓库">
                <el-option
                  v-for="item in storageIdOptions"
                  :key="item.storageId"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
        </el-col>
        <el-col :span="4">
            <el-cascader
              class="width-100"
              v-model="selectCategoryOptions"
              :options="categoryOptions"
              :props="categoryProps"
              placeholder="请选择商品分类"
              clearable
            ></el-cascader>
        </el-col>
        <el-col :span="4">
            <el-select v-model="searchForm.status" placeholder="请选择状态">
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
        </el-col>
        <el-col :span="4">
          <el-input v-model="searchForm.holdCode" placeholder="请输入入库单编码"></el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="danger" @click="getDatas()">搜索</el-button>
          <el-button @click="resetSearch()">重置</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="tableList">
      <el-table :data="tableDatas">
        <el-table-column prop="applyHoldId" label="申请持仓ID"></el-table-column>
        <el-table-column prop="holdCode" label="入库单编码"></el-table-column>
        <el-table-column prop="variety" label="商品分类"></el-table-column>
        <el-table-column prop="name" label="仓库名称"></el-table-column>
        <el-table-column prop="quantity" label="数量">
           <template slot-scope="scope">
            <span>{{ scope.row.quantity?scope.row.quantity:'--'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="specifications" label="商品属性">
            <template slot-scope="scope">
            <span>{{ scope.row.specifications | firstAttributeTransformate}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="variety" label="品种"></el-table-column> -->
        <el-table-column prop="weight" label="重量">
               <template slot-scope="scope">
            <span>{{ scope.row.weight }}{{ scope.row.unit }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="putWarehousTime" label="预计入库时间">
          <template slot-scope="scope">
            {{ scope.row.putWarehousTime | DateFormateDay }}
          </template>
        </el-table-column>
        <el-table-column prop="realPutWarehousTime" label="实际入库时间">
          <template slot-scope="scope">
            {{ scope.row.realPutWarehousTime | DateFormate }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            {{ scope.row.applyHoldStatus | testQualityStatusFormate }}
          </template>
        </el-table-column>
        <el-table-column prop label="操作" width="90">
          <template slot-scope="scope">
            <!-- <el-button @click="viewDialog(scope.row)" size="mini">查看</el-button> -->
            <!-- <el-button @click="download(scope.row)" type="danger" size="mini">下载</el-button> -->
            <el-button v-if="right.delivery&&scope.row.applyHoldStatus!='D'" :disabled="scope.row.applyHoldStatus=='Y'&&scope.row.inspectionStatus=='T'"   @click="checkout(scope.row)" type="warning" size="mini">出库</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage"
      ></el-pagination>
    </div>
    <deliveryDialog ref="deliveryDialog" :source="source" @reLoad="getDatas()" bsFlag="S" deliveryType="deposit" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import deliveryDialog from "./deliveryDialog";
export default {
  data() {
    return {
      current: 1,
      pageSize: 10,
      totalPage: null,
      tableDatas: [],
      searchForm: {
        storageId: null,
        status: null,
        holdId: null
      },
      dialogVisible: false,
      delivery: {
        warehousId: null,
        type: 0
      },
      source: 1,
      right: {
        delivery: false
      },
      selectCategoryOptions:[],
      categoryOptions:[],
      statusOptions: [
        // { value: 'W', label: "待入库" },
        { value: 'Y', label: "已入库" },
        // { value: 'T', label: "质检通过" },
        { value: 'F', label: "质检不通过" },
        { value: 'D', label: "已提货" },
        // { value: 'U', label: "已撤销" },
      ],
      storageIdOptions: [],
      categoryProps: {
        label: "className",
        value: "className",
        checkStrictly: false
      },
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  components: {
    deliveryDialog
  },
  mounted() {
    this.getDatas();
    this.getRights();
    this.getCommodityCategory();
    this.getStorageIdOptions();
  },
  methods: {
    getRights() {
      this.loadRight().then((loadResult) => {
        if (loadResult == 'loadRightSuccess') {
          this.right.delivery = this.isHasRight('tc-trade-deliveryWarehous');
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
      resetSearch() {
      this.searchForm = {
        storageId: null,
        status: null,
        holdId: null,
        holdCode: null
      };
      this.selectCategoryOptions = []
      this.getDatas();
    },
    getCommodityCategory() {
      http.getRes(protocolFwd.param_queryVarieties).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          let categoryArr = value
          this.categoryOptions = this.clearChildren(categoryArr);
        } else {
          this.categoryOptions = []
          this.$EL_MESSAGE(message);
        }
      });
    },
    clearChildren(Arr) {
      Arr.forEach(element => {
        if(element&&element.children){
          if(element.children.length==0){
            delete element['children']
          }else{
            this.clearChildren(element.children)
          }
        }
      });
      return Arr
    },
     getStorageIdOptions() {
      http.postFront(protocolFwd.param_getStorage).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.storageIdOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getDatas() {
      protocolFwd.param_queryDepositReceiptHold.param.applyHoldId = this.searchForm.holdId
      protocolFwd.param_queryDepositReceiptHold.param.sname = this.searchForm.storageId
      protocolFwd.param_queryDepositReceiptHold.param.variety = this.selectCategoryOptions[this.selectCategoryOptions.length - 1]
      protocolFwd.param_queryDepositReceiptHold.param.applyHoldStatus = this.searchForm.status
      protocolFwd.param_queryDepositReceiptHold.param.holdCode = this.searchForm.holdCode

      protocolFwd.param_queryDepositReceiptHold.param.page = this.current - 1;
      protocolFwd.param_queryDepositReceiptHold.param.size = this.pageSize;
      protocolFwd.param_queryDepositReceiptHold.param.sort = [{property: "putWarehousTime", direction: "DESC"}];
      // protocolFwd.param_queryDepositReceiptHold.param.applyHoldStatus = ['Y','F','D'];
      // protocolFwd.param_queryDepositReceiptHold.param.buyFirmId = null;
      // protocolFwd.param_queryDepositReceiptHold.param.fStatus = [0, 1, 99];
      // protocolFwd.param_queryDepositReceiptHold.param.wfirmId = this.sessionInfoGetter.firmId;
      http.postFront(protocolFwd.param_queryDepositReceiptHold).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          for (let i = 0; i < value.content.length; i++) {
            if (!value.content[i].holdCode) {
              value.content[i].holdCode = '---'
            }
          }
          this.tableDatas = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    viewDialog(row) {},
    download(row) {},
    checkout(row) {
      this.$refs.deliveryDialog.showDialog(row);
    }
  }
};
</script>
<style scoped lang="scss">
.subPage {
  padding: 0 15px;
}
.subPageTitle {
  overflow: hidden;
}
.subPageTitle .subPageTitleText {
  float: left;
  line-height: 40px;
}
.subPageTitle .subPageTitleText p {
  font-size: 16px;
}
.tableList {
  margin-top: 20px;
}
.tableList {
  margin: 10px 0;
  /deep/ .el-table thead th{
    background-color: rgba(5, 64, 106, 1);
    color: #fff;
  }
  .allSelectBox{
    line-height: 30px;
    margin-left: 20px;
  }
}
.deliveryContent {
  line-height: 30px;
}
.subPage .pagination {
  margin-top: 15px;
}
</style>
